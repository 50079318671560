import React, { useCallback, useState } from "react";
import "./App.css";
import { useDropzone } from "react-dropzone";
import Loader from "./Loader";
import {
  postZip,
  getStatus,
  getResult,
  getBriefResult,
  getImageLink
} from "./sources";

function App() {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [operationId, setOperationId] = useState("");

  const onDrop = useCallback(async acceptedFiles => {
    console.log("files", acceptedFiles);
    try {
      setLoading(true);

      let response = await postZip(acceptedFiles[0]);
      const { operationId } = response.data;

      await getStatus(operationId);
      const result = await getBriefResult(operationId);
      setResult(result.data);
      setOperationId(operationId);
    } catch (e) {
      console.log("Ошибка", e);
      setError(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open,
    acceptedFiles
  } = useDropzone({ onDrop });

  const handleOpen = e => {
    e.stopPropagation();
    open(e);
  };

  const handleReset = () => {
    setResult(null);
    setError(null);
  };

  if (result || error) {
    return (
      <div className="App">
        <div className="app-content result">
          <h1>Результат обработки:</h1>
          {result && (
            <>
              {result.isPredicted && (
                <div className="result-image">
                  <img src={getImageLink(operationId)} alt="" />
                </div>
              )}
              <p>
                {result.isPredicted
                  ? `Выявлены патологические изменения. Вероятность ${Math.floor(
                      result.probability * 100
                    )}%`
                  : "Норма. Патологических изменений не выявлено"}
                .
              </p>
            </>
          )}
          {error && <p>Произошла ошибка: {error}</p>}
          <br />
          <button onClick={handleReset}>Загрузить заново</button>
        </div>
      </div>
    );
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="App">
      <div {...getRootProps()} className="app-content drop-area">
        <div className="drop-area__description">
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Поместите файл в область для начала загрузки</p>
          ) : (
            <p>
              Перетащите ZIP-архив с DICOM файлами в область загрузки или
              нажмите кнопку "Загрузить"
            </p>
          )}
          <button onClick={handleOpen}>Загрузить</button>
        </div>
      </div>
    </div>
  );
}

export default App;
